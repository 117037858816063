import React, { useEffect } from "react";
import { CookieNotice } from "gatsby-cookie-notice";
import { useLocation } from "@reach/router"; // this helps tracking the location
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import styled from "styled-components";

const CookieNoticeStyles = styled.div`
  .bg-cookie {
    padding: 1rem 0;
    background: var(--black);
    color: var(--white);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 100%;

    .container {
      padding: 1rem;
    }

    h4 {
      color: var(--cookies);
      text-align: center;
      margin-top: 0;
    }

    p {
      font-size: 1rem;
    }
  }

  .cookie-notice {
    display: flex;
    justify-content: flex-end;
  }

  .btn {
    border-style: none;
    background-color: var(--grey);
    font-size: 1rem;
    padding: 0.5rem;
  }

  .btn-primary {
    margin-left: 2rem;
    margin-right: 10rem;
    background-color: var(--cookies);
  }

  .cookie-list {
    input {
      width: 1rem;
    }

    label {
      vertical-align: super;
    }

    p {
      margin-bottom: 2rem;
    }
  }
`;

export default function CookieNoticeBanner() {
  const location = useLocation();

  useEffect(() => {
    initializeAndTrack(location);
  }, [location]);

  return (
    <CookieNoticeStyles>
      <CookieNotice
        acceptButtonText={"Accept"}
        declineButton={false}
        personalizeButtonText={"Manage Cookies"}
        cookies={[
          {
            name: "necessary",
            editable: false,
            default: true,
            title: "Essential Cookies",
            text:
              "Essential cookies are necessary for the proper functioning of the site. The site cannot function properly without them.",
          },
          {
            name: "gatsby-gdpr-google-analytics",
            editable: true,
            default: true,
            title: "Google Analytics",
            text:
              "Google Analytics is a statistical tool of Google allowing to measure the audience of the website.",
          },
        ]}
      >
        <p>
          We use cookies to improve your experience and support our mission.
        </p>
      </CookieNotice>
    </CookieNoticeStyles>
  );
}
