import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import Logo from "../Logo";
import Linka from "../../utility/Linka";
import DesktopMenu from "./DesktopMenu";

const DesktopNavStyles = styled.nav`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  color: white;

  @media (max-width: 1280px) {
    grid-template-columns: 1fr;

    .logo-url,
    .online-shop {
      grid-row: 1;
    }
  }

  .navbar {
    display: flex;
    justify-content: center;
    align-items: center;

    ul {
      list-style-type: none;
      display: flex;
      align-items: baseline;
      margin-bottom: 0;
    }

    .category {
      display: flex;
      align-items: flex-start;
      margin-right: 2rem;

      font-family: var(--header-font), sans-serif;
      font-size: 1.2rem;
      cursor: pointer;
      white-space: nowrap;
    }
  }

  a {
    font-size: 1.2rem;
    white-space: nowrap;
    text-decoration: none;
    color: var(--white);
    margin-right: 2rem;
    transition: all 1s;

    border-bottom: 0px solid rgba(0, 0, 0, 0);

    &:hover {
      color: var(--color4);
      border-bottom: 2px solid var(--color4);
    }
  }

  .online-shop {
    display: grid;
    justify-content: end;

    a {
      display: flex;

      &:hover {
        color: var(--white);
        border-bottom: none;
      }

      img {
        width: 200px;
        height: 200px;
        margin-right: 0.3em;
      }
    }
  }

  @media (max-width: 800px) {
    a,
    .navbar .category {
      font-size: 1rem;
    }
  }

  .logo-url {
    &:hover {
      border-bottom: 0;
    }
  }

  .arrow {
    width: 15px;
    height: 15px;
    padding-left: 5px;
  }

  .active {
    color: var(--color4) !important;
    border-bottom: 2px solid var(--color4) !important;
  }

  .nested-menu {
    position: absolute;
    bottom: -37px;
    left: 0;
    width: 100%;
    height: 3rem;
    background-color: var(--black);
    justify-content: center;

    li a {
      font-size: 1rem;
    }
  }

  .support-button {
    padding: 10px;
    border: 1px solid var(--white);
  }
`;

export default function NavbarDesktop({ menus, location }) {
  const [posInNav, setPosInNav] = useState([0]);

  const UlRef = useRef();

  useEffect(() => {
    const handleClickOut = (e) => {
      if (!UlRef.current.contains(e.target) && posInNav.length > 1) {
        setPosInNav([0]);
      }
    };

    document.addEventListener("click", handleClickOut);

    return () => {
      document.removeEventListener("click", handleClickOut);
    };
  }, [UlRef, posInNav]);

  return (
    <DesktopNavStyles>
      <Linka to="/" className="logo-url">
        {" "}
        <Logo />
      </Linka>

      <div className="navbar">
        <ul
          ref={(NewUlRef) => {
            UlRef.current = NewUlRef;
          }}
        >
          <li>
            <Linka
              to="/"
              className={location.pathname === "/" ? "active" : ""}
              onClick={() => setPosInNav([0])}
            >
              HOME
            </Linka>
          </li>

          <DesktopMenu
            data={menus}
            level={[0]}
            posInNav={posInNav}
            setPosInNav={setPosInNav}
            location={location}
          />

          <li>
            <Linka
              to="https://shop.familyilove.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              SHOP
            </Linka>
          </li>
        </ul>
      </div>
    </DesktopNavStyles>
  );
}
