import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";

import NavbarDesktop from "./NavbarDesktop";
import NavbarMobile from "./NavbarMobile";

const NavbarStyles = styled.nav`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;

  background-color: var(--black);

  .desktop {
    display: grid;

    @media (max-width: 1280px) {
      margin-bottom: 1rem;
    }
  }

  .mobile {
    display: none;
  }

  @media (max-width: 950px) {
    .desktop {
      display: none;
    }

    .mobile {
      display: grid;
    }
  }

  @media (pointer: none), (pointer: coarse) {
    .desktop {
      display: none;
    }

    .mobile {
      display: grid;
    }
  }
`;

export default function Navbar({ location }) {
  const data = useStaticQuery(graphql`
    query {
      pages: allSanityPage {
        nodes {
          slug {
            current
          }
          title
        }
      }

      categories: allSanityCategory {
        edges {
          node {
            slug {
              current
            }
            title
          }
        }
      }

      pillarPosts: allSanityBlog(
        filter: { publicityStatus: { eq: "PUBLISHED" }, pillar: { eq: true } }
      ) {
        edges {
          node {
            title
            slug {
              current
            }

            blogCategory {
              slug {
                current
              }
            }
          }
        }
      }
    }
  `);

  const menus = [
    {
      title: "BLOG",
      children: [
        ...data.categories.edges.map((category) => ({
          title: category.node.title.toUpperCase(),
          children: [
            {
              title: (
                <strong>ALL {category.node.title.toUpperCase()} POSTS</strong>
              ),
              url: `/${category.node.slug.current}/`,
            },
            ...data.pillarPosts.edges
              .filter(
                (post) =>
                  post.node.blogCategory.slug.current ===
                  category.node.slug.current,
              )
              .map((pillarPost) => ({
                title: pillarPost.node.title.toUpperCase(),
                url: `/${category.node.slug.current}/${pillarPost.node.slug.current}/`,
              })),
          ],
        })),
      ],
    },

    ...data.pages.nodes.map((page) => ({
      title: page.title.toUpperCase(),
      url: `/${page.slug.current}/`,
    })),
  ];

  return (
    <NavbarStyles>
      <div className="desktop">
        <NavbarDesktop location={location} menus={menus} />
      </div>

      <div className="mobile">
        <NavbarMobile location={location} menus={menus} />
      </div>
    </NavbarStyles>
  );
}
