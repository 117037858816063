import { Link } from "gatsby";
import React, { useEffect, useState } from "react";

const Linka = (props) => {
  const [isExternal, setIsExternal] = useState();
  const [aProps, setAProps] = useState({});
  const [extClassName, setExtClassName] = useState("");

  useEffect(() => {
    setIsExternal(props.to ? props.to.includes("://") : undefined);
  }, [props.to]);

  useEffect(() => {
    const newAProps = { ...props };
    delete newAProps.to;
    delete newAProps.children;
    setAProps(newAProps);
  }, [props]);

  useEffect(() => {
    setExtClassName(
      `${props.className ? props.className : ""} ${props.to ? "" : "disabled"}`
    );
  }, [props.className, props.to]);

  return !isExternal ? (
    <Link
      {...props}
      to={isExternal === undefined ? "" : props.to}
      className={extClassName}
    />
  ) : (
    <a href={props.to} {...aProps} className={extClassName}>
      {props.children}
    </a>
  );
};

export default Linka;
