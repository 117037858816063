import React from "react";
import { RightArrow } from "@styled-icons/boxicons-regular";
import { ChevronLeft } from "@styled-icons/boxicons-regular";

import Linka from "../../utility/Linka";

import returnSpaceCheck from "../../../utils/returnOrSpaceCheck";

const MobileMenu = ({ data, level, posInNav, setPosInNav, setMenuOpen, location }) => {
  const checkIfLevelOpen = (levels, posInNav) => {
    let shouldOpen = true;
    levels.forEach((level, i) => {
      if (posInNav[i] !== level) {
        shouldOpen = false;
      }
    });
    return shouldOpen;
  };

  return data.map((item, i) => {
    const nextLevel = [...level, i];

    return (
      <li key={nextLevel}>
        {item.children ? (
          <>
            <span
              role="button"
              tabIndex="0"
              aria-label="dropdown menu"
              onKeyDown={(e) => returnSpaceCheck(e) && setPosInNav(nextLevel)}
              className="category"
              onClick={() => setPosInNav(nextLevel)}
            >
              {item.title}
              <span className="arrow">
                <RightArrow />
              </span>
            </span>

            <ul
              className={`sub-nav ${!checkIfLevelOpen(nextLevel, posInNav) ? "invisible-nav" : ""
                }`}
            >
              {nextLevel.length > 1 && (
                <span
                  role="button"
                  tabIndex="0"
                  onKeyDown={(e) =>
                    returnSpaceCheck(e) &&
                    setPosInNav(
                      posInNav.filter((_, j) => j < posInNav.length - 1)
                    )
                  }
                  className="back"
                  onClick={() =>
                    setPosInNav(
                      posInNav.filter((_, j) => j < posInNav.length - 1)
                    )
                  }
                >
                  <span className="back-arrow">
                    <ChevronLeft />
                  </span>
                  <span className="back-text">Back</span>
                </span>
              )}

              <MobileMenu
                data={item.children}
                level={nextLevel}
                posInNav={posInNav}
                setPosInNav={setPosInNav}
                setMenuOpen={setMenuOpen}
                location={location}
              />
            </ul>
          </>
        ) : item.url ? (
          <Linka
            to={item.url}
            className={location.pathname === item.url ? "active" : ""}
            onClick={() => setMenuOpen(false)}
          >
            {item.title}
          </Linka>
        ) : (
          <span>{item.title}</span>
        )}
      </li>
    );
  });
}

export default MobileMenu;
