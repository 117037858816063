import React from "react";
import styled from "styled-components";

import {
  Facebook,
  Instagram,
  Twitter,
  Youtube,
} from "@styled-icons/boxicons-logos";

import Linka from "./Linka";

const SocialMediaStyles = styled.div`
  .social {
    margin-right: 10rem;

    li {
      margin: 0.3rem;
    }
  }

  svg {
    width: 36px;
    height: 36px;
    top: 0.125em;
    color: var(--white);
  }

  .support-icon svg {
    color: var(--logo-green);
  }
`;

const socials = [
  {
    name: <Facebook />,
    link: "https://www.facebook.com/FamilyILove.Band",
  },
  {
    name: <Instagram />,
    link: "https://www.instagram.com/family.i.love/",
  },
  {
    name: <Twitter />,
    link: "https://twitter.com/FamilyILoveBand",
  },
  {
    name: <Youtube />,
    link: "https://www.youtube.com/@familyilove",
  },
];

export default function SocialMedia() {
  return (
    <SocialMediaStyles>
      <ul className="social">
        {socials.map((social, i) => (
          <li key={i}>
            <Linka
              to={social.link}
              target="_blank"
              rel="noopener noreferrer nofollow"
              className={social.class}
            >
              {social.name}
            </Linka>
          </li>
        ))}
      </ul>
    </SocialMediaStyles>
  );
}
