import React from "react";
import Linka from "../../utility/Linka";
import { DownArrow, UpArrow } from "@styled-icons/boxicons-solid";
import returnOrSpaceCheck from "../../../utils/returnOrSpaceCheck";

const DesktopMenu = ({ data, level, posInNav, setPosInNav, location }) => {
  const togglePosInNav = (level, nextLevel, levelOpen) => {
    if (!levelOpen) {
      setPosInNav(nextLevel);
    } else {
      setPosInNav(level);
    }
  };

  const checkIfLevelOpen = (levels, posInNav) => {
    let shouldOpen = true;
    levels.forEach((level, i) => {
      if (posInNav[i] !== level) {
        shouldOpen = false;
      }
    });
    return shouldOpen;
  };

  return data.map((item, i) => {
    const nextLevel = [...level, i];
    const levelOpen = checkIfLevelOpen(nextLevel, posInNav);

    return (
      <li key={nextLevel}>
        {item.children ? (
          <>
            <span
              role="button"
              tabIndex="0"
              aria-label="dropdown menu"
              onKeyDown={(e) =>
                returnOrSpaceCheck(e) &&
                togglePosInNav(level, nextLevel, levelOpen)
              }
              className="category"
              // ${
              //   item.children.filter((child) =>
              //     location.pathname.includes(child.url),
              //   ).length > 0 || levelOpen
              //     ? "active"
              //     : ""
              // }
              onClick={() => togglePosInNav(level, nextLevel, levelOpen)}
            >
              <div className="category-text">{item.title}</div>
              <span className="arrow">
                {levelOpen ? <UpArrow /> : <DownArrow />}
              </span>
            </span>

            {levelOpen && (
              <ul className="nested-menu">
                <DesktopMenu
                  data={item.children}
                  level={nextLevel}
                  posInNav={posInNav}
                  setPosInNav={setPosInNav}
                  location={location}
                />
              </ul>
            )}
          </>
        ) : item.url ? (
          <Linka
            to={item.url}
            className={location.pathname === item.url ? "active" : ""}
            onClick={() => setPosInNav([0])}
          >
            {item.title}
          </Linka>
        ) : (
          <span>{item.title}</span>
        )}
      </li>
    );
  });
};

export default DesktopMenu;
