import React, { useState } from "react";
import { Menu } from "@styled-icons/boxicons-regular";

import MobileMenu from "./MobileMenu";
import Logo from "../Logo";
import Linka from "../../utility/Linka";
import styled from "styled-components";

const NavbarMobileStyles = styled.div`
  @keyframes removeDisplay {
    0% {
      opacity: 1;
      transform: translateY(0);
    }

    99% {
      opacity: 1;
      transform: translateY(0);
    }

    100% {
      opacity: 0;
      transform: translateY(-100vh);
    }
  }

  position: relative;
  width: 100vw;

  color: white;

  .static-nav-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background-color: var(--black);
    position: relative;
    z-index: 100;
  }

  ul {
    padding-inline-start: 0;
    width: 100%;
    position: absolute;
    top: 0;
    left: 100vw;
    transition: left 0.25s ease-in-out;

    background-color: var(--black);
    margin-top: 0;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;

    &.invisible-nav {
      animation: removeDisplay 0.5s forwards;
    }

    li {
      list-style-type: none;
      font-size: 1rem;
      margin-left: 2rem;

      a {
        color: var(--white);

        &:hover {
          color: var(--color4);
        }
      }
    }
  }

  .category {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    cursor: pointer;

    .arrow {
      justify-self: end;
      margin-right: 2rem;

      svg {
        width: 15px;
        height: 15px;
      }
    }
  }

  .online-shop,
  .support-us {
    a {
      display: flex;
      align-items: center;

      svg {
        width: 30px;
        height: 30px;
        margin-right: 0.3em;
      }
    }
  }

  .back {
    width: 60px;
    background: var(--color4);
    color: var(--black);
    padding: 2px;
    border-radius: 4px;
    padding-right: 10px;
    margin-left: 2rem;
    margin-top: 1rem;
  }

  .sub-nav li {
    margin-left: 2rem;
  }

  .back-arrow {
    margin-right: 0.2rem;
  }

  .back-arrow svg {
    width: 20px;
    height: 20px;
  }

  .back-text {
    vertical-align: middle;
  }

  .menu-button {
    display: flex;
    justify-content: flex-end;

    button {
      border: none;
      background-color: transparent;
      font-size: 1rem;
      color: var(--white);
      padding: 0;

      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;

      svg {
        width: 30px;
        height: 30px;
      }
    }
  }

  .menu {
    transform: translateY(-100vh);
    transition: transform 0.5s ease-in-out;

    &.open-menu {
      transform: translateY(0);
    }
  }
`;

const NavbarMobile = ({ menus, location }) => {
  const [posInNav, setPosInNav] = useState([0]);
  const [menuOpen, setMenuOpen] = useState(false);

  const openNavMenu = () => {
    setMenuOpen(true);
    setPosInNav([0]);
  };

  return (
    <NavbarMobileStyles onMouseLeave={() => setMenuOpen(false)}>
      <div className="static-nav-wrapper">
        <div className="nav-logo">
          <Linka to="/">
            <Logo />
          </Linka>
        </div>

        <div className="menu-button">
          <button
            onClick={() => (menuOpen ? setMenuOpen(false) : openNavMenu())}
          >
            Menu <Menu />
          </button>
        </div>
      </div>

      <div className={`menu ${menuOpen ? "open-menu" : ""}`}>
        <ul
          style={{
            left: `-${(posInNav.length - 1) * 100}vw`,
          }}
        >
          <li>
            <Linka
              to="/"
              className={location.pathname === "/" ? "active" : ""}
              onClick={() => setMenuOpen(false)}
              style={{ paddingTop: "1rem" }}
            >
              HOME
            </Linka>
          </li>

          <MobileMenu
            data={menus}
            level={[0]}
            posInNav={posInNav}
            setPosInNav={setPosInNav}
            setMenuOpen={setMenuOpen}
            location={location}
          />

          <li>
            <div className="online-shop">
              <Linka
                to="https://shop.familyilove.com"
                target="_blank"
                rel="noreferrer noopener"
              >
                SHOP
              </Linka>
            </div>
          </li>

          {/* <li>
            <div className="support-us">
              <Linka to="/support" className="support-button">
                SUPPORT THE BAND
              </Linka>
            </div>
          </li> */}
        </ul>
      </div>
    </NavbarMobileStyles>
  );
};

export default NavbarMobile;
